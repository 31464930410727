// FONTS
$font-family: "Rubik", sans-serif;

$font-size-smaller: 14px;
$font-size-normal: 16px;
$font-size-larger: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 28px;
$font-size-largest: 36px;

$font-color: #5e5c7f;

$font-bold: 700;

// COLORS
$color-light: #f9f9f9;
$color-dark: #342054;
$color-white: #fff;
$color-active: #ffd15c;
$color-primary: #342054;